import * as React from 'react'
import { Layout } from '../../components/common/Layout'
import { SEO } from '../../components/common/SEO'
import { ContactFin, ContactHeader } from '../../components/domains/contact'

const ContactPage = () => {
	return (
		<Layout
			location={[
				{ name: 'お問い合わせ', path: '/contact' },
				{ name: 'お問い合わせ 入力内容確認', path: '/contact/confirm' },
				{ name: 'お問い合わせ 送信完了', path: '/contact/fin' },
			]}
		>
			{' '}
			<SEO title="お問い合わせ" description="" />
			<ContactHeader currentStep={3} />
			<ContactFin />
		</Layout>
	)
}

export default ContactPage
